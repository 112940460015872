// composable function for querying and caching strapi api
import strapiConfig from '@/config/strapiConfig';

export interface Strapi4RequestParams {
  fields?: Array<string>
  populate?: string | Array<string>
  filters?: Record<string, any>
}

/**
 * prepares an API URL for querying strapi
 * for documentation of strapi rest api visit https://docs.strapi.io/dev-docs/api/rest
 * NOTE: params.filters doesn't handle $and or $or operators... if those are needed this function will need to be improved
 * NOTE: params.populate doesn't handle complex nested level populate commands such as specifying media fields on related items. These will need to be built manually and appended to the URL
 *
 * @param contentType - the contentType key to query from strapi API
 * @param params - request params to include in API request
 * @returns a formed url based on the strapi config and the content type
 */
export function getStrapiLink(contentType: string, params?: Strapi4RequestParams) {
  const { locale } = useI18n();
  let apiURL = `${strapiConfig.url}${strapiConfig.prefix}/${contentType}?locale=${locale.value}`;
  // add querystring params if they are provided
  if (params) {
    if (params.hasOwnProperty("fields") && params.fields?.length) {
      // fields
      for (let index = 0; index < params.fields.length; index++) {
        apiURL += `&fields[${index}]=${params.fields[index]}`;
      }
    }
    if (params.hasOwnProperty("populate")) {
      // populate
      if (params.populate === '*') {
        apiURL += '&populate=*';
      } else if (Array.isArray(params.populate)) {
        // assemble populate querystring params
        for (let index = 0; index < params.populate.length; index++) {
          apiURL += `&populate[${index}]=${params.populate[index]}`;
        }
      }
    }
    if (params.hasOwnProperty("filters")) {
      // add filters
      // todo filters doesn't handle $and or $or operators... if those are needed this function will need to be improved
      for (const key in params.filters) {
        for (const operator in params.filters[key]) {
          apiURL += `&filters[${key}][${operator}]=${params.filters[key][operator]}`
        }
      }
    }
  }
  if (!useRuntimeConfig().public.ssr) {
    console.info(`fetching ch4pathways data: ${contentType} at ${apiURL}`);
  }
  return apiURL;
};